import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './css/mainStyles.scss';
import './css/MainComponent.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
// import thunk from 'redux-thunk';
//import { createStore, applyMiddleware } from 'redux';
import newStore from './features/store';

//import 'bootstrap/dist/css/bootstrap.css';

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={newStore}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// ReactDOM.render(
//   ,
// document.getElementById('root'));

// serviceWorker.unregister();
