import React, { Suspense, lazy, useEffect } from 'react';
// import { Canvas } from '@react-three/fiber';
// import {
//   Navigate,
//   Route,
//   BrowserRouter as Router,
//   Routes
// } from "react-router-dom";

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// setupConfig({
//   mode: 'md'
// });

// import AuthorizationComponent from './components/AuthorizationComponent';
//import RestorepasswordComponent from './components/RestorepasswordComponent';
import HomeService from './services/Homeservice';
//import MainComponent from './components/MainComponent';
import ErrorComponent from './components/ErrorComponent';
// import SpaceEarthFiber from './components/adminComponents/SpaceEarthFiber';
const MainComponent = lazy(() => import('./components/MainComponent'));

//import MainComponent from './components/MainComponent';

const AuthorizationComponent = lazy(() => import('./components/AuthorizationComponent'));
// const SpaceEarthFiber = lazy(() => import('./components/adminComponents/SpaceEarthFiber'));

// const RestorepasswordComponent = lazy(() => import('./components/RestorepasswordComponent'));
// const ApplyComponent = lazy(() => import('./components/ApplyComponent'));
// const PaymentComponent = lazy(() => import('./components/PaymentComponent'));
// const BusinessDashboard = lazy(() => import('./components/businessDashboard'));
// const DetailComponent = lazy(() => import('./components/DetailComponent'));
// const MDetailComponent = lazy(() => import('./components/businessComponents/MDetailComponent'));
// const SubDetailComponent = lazy(() => import('./components/SubDetailComponent'));
// const ProfileComponent = lazy(() => import('./components/ProfileComponent'));
// const RateComponent = lazy(() => import('./components/RateComponent'));
// const SelectRoleComponent = lazy(() => import('./components/SelectRoleComponent'));
// const BloggerDashboardComponent = lazy(() => import('./components/BloggerDashboardComponent'));
// const DetailTaskComponent = lazy(() => import('./components/DetailTaskComponent'));
// const SuggestComponent = lazy(() => import('./components/SuggestComponent'));
// const ContactListComponent = lazy(() => import('./components/contactListComponent'));
// const TaskComponent = lazy(() => import('./components/TaskComponent'));
// const RedirectComponent = lazy(() => import('./components/RedirectComponent'));

// const BloggerAnswersComponent = lazy(() => import('./components/BloggerAnswersComponent'));
// const AdminComponent = lazy(() => import('./components/adminComponents/AdminComponent'));
const AboutUsComponent = lazy(() => import('./components/AboutUsComponent'));
// const ConfirmComponent = lazy(() => import('./components/serviceComponents/ConfirmComponent'));
// const ActivationEmail = lazy(() => import('./components/emailTemplates/ActivationEmail'));
// const ChooseWayComponent = lazy(() =>
//   import('./components/ApplyComponents_model1/ChooseWayComponent')
// );
// const NewApplyComponent = lazy(() =>
//   import('./components/ApplyComponents_model1/NewApplyComponent')
// );
// const VideoComponent = lazy(() => import('./helperComponents/VideoComponent'));
// const Model1DetailTaskComponent = lazy(() =>
//   import('./components/ApplyComponents_model1/Model1DetailTaskComponent')
// );
// const creatorComponent = lazy(() => import('./components/introductionComponents/creatorComponent'));
// const BusinessIntroComponent = lazy(() =>
//   import('./components/introductionComponents/BusinessIntroComponent')
// );
// const userProfileComponent = lazy(() =>
//   import('./components/profileComponents/userProfileComponent')
// );
// const exploreProfileComponent = lazy(() =>
//   import('./components/profileComponents/exploreProfileComponent')
// );
// const businessUserProfileComponent = lazy(() =>
//   import('./components/profileComponents/businessUserProfileComponent')
// );
// const WhyEchohubComponent = lazy(() =>
//   import('./components/introductionComponents/WhyEchohubComponent')
// );
// const MSubdetailComponent = lazy(() =>
//   import('./components/businessComponents/MSubdetailComponent')
// );
// const BloggerListComponent = lazy(() =>
//   import('./components/BloggerPullComponents/BloggerListWComponent')
// );
// const PaypalMembershipComponent = lazy(() =>
//   import('./components/PaymentSubComponents/PaypalMembershipComponent')
// );
// const BloggerListWhiteComponent = lazy(() =>
//   import('./components/BloggerPullComponents/BloggerListWhiteComponent')
// );
// const LastCreatorsPostComponent = lazy(() =>
//   import('./components/BloggerPullComponents/LastCreatorsPostComponent')
// );
const NewsComponent = lazy(() => import('./components/NewsComponents/NewsComponent'));
const NewsListComponent = lazy(() => import('./components/NewsComponents/NewsListComponent'));
const ShowNewsComponent = lazy(() => import('./components/NewsComponents/ShowNewsComponent'));
const SquareLawComponent = lazy(() =>
  import('./components/squareLawComponents/squareLawComponent')
);

const App = () => {
  useEffect(() => {
    //connect to server
    HomeService.initialConnect();
    //connect to server
  }, []);

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Suspense>
          <MainComponent />
        </Suspense>
      ),
      errorElement: <ErrorComponent />
    },
    {
      path: 'news/:id',
      element: <ShowNewsComponent />
    },
    {
      path: 'login',
      element: <AuthorizationComponent />
    },
    {
      path: 'squarelawcalculator',
      element: <SquareLawComponent />
    },
    {
      path: 'latest-news',
      element: <NewsListComponent />
    },
    {
      path: 'create-news/:id',
      element: <NewsComponent />
    },
    // {
    //   path: 'testthree',
    //   element: (
    //     <div id="canvas-container">
    //       <Canvas
    //         camera={{
    //           position: [0.7377239131049339, 0.7318927848034871, -1.796331519153829],
    //           fov: 75,
    //           aspect: 1.3616734143049933
    //         }}
    //       >
    //         <Suspense>
    //           <SpaceEarthFiber />
    //         </Suspense>
    //       </Canvas>
    //     </div>
    //   )
    // },
    {
      path: 'about',
      element: (
        <Suspense>
          <AboutUsComponent />
        </Suspense>
      )
    },
    {
      path: 'main',
      element: (
        <Suspense>
          <MainComponent />
        </Suspense>
      )
    }
  ]);

  return <RouterProvider router={router} />;

  // return (
  //             <Router>
  //                   <Routes>
  //                     <Route path="/home" component={MainComponent} exact={true} />

  //                     <Route path="/main"  component={MainComponent}  />
  //                     <Route path="/about"  back={true} component={AboutUsComponent}  />
  //                     <Route path="/follow/:id" back={true} component={RedirectComponent} exact={true} />
  //                     <Route path="/confirm/:id" back={true} component={ConfirmComponent} exact={true} />
  //                     <Route path="/why" back={true} component={WhyEchohubComponent}  />
  //                     <Route path="/bloggerlist" back={true} component={BloggerListComponent}  />
  //                     <Route path="/create-news/:id" back={true} component={NewsComponent} exact={true} />
  //                     <Route path="/news/:id" back={true} component={ShowNewsComponent} exact={true} />
  //                     <Route path="/latest-news"  back={true} component={NewsListComponent} />
  //                     <Route path="/test"  back={true} component={LoaderComponent} exact={true} />
  //                     <Route exact path="/" render={() => <Navigate to="/main" />} />

  //                   </Routes>
  //             </Router>
  // );
};
//0 - under consideration by business,1 confirmed,2 rejected, 3 Suggest to update things
export default App;
