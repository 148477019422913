import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en: {
    login: 'Login/Registration',
    login_button: 'Join',
    password_length: 'Password is too short - should be at least 6 symbol.',
    restore_password: 'Forgot password',
    restore_button: 'Restore',
    user_not_found:
      'User with this email not found please try another email or may be you are not registered',
    system_notification: 'Notification',
    password_mail_notification: 'The password has been sent to your email',
    company_name: 'Enter company name',
    title_name: 'Enter business url',
    description_name: 'Description',
    description_placeholder:
      'Please write description about your business and what you want to hear from creators',
    location_name: 'Enter address',
    location_error: 'Please choose location',
    time_name: 'Choose time',
    amount_name: 'Amount ',
    amount_placeholder: 'lets count how many creators will involved',
    date_name: 'Start date',
    continue_button: 'Continue',
    count_of_bloggers: 'Involved creators count',
    count_of_subscribers: 'Approximate coverage',
    choose_payment: 'Please make a payment',
    request: 'Request ',
    paid: 'Paid ',
    approximate_reach: 'Approximate reach ',
    involved_bloggers: 'Involved Creators ',
    platforms_in_use: 'Platforms in use ',
    tiktok: 'Tiktok ',
    instagramm: 'Instagram ',
    facebook: 'Facebook ',
    twitter: 'Twitter ',
    list_of_bloggers: 'List of Creators ',
    view: 'View',
    profile: 'Profile',
    detail: 'Detail',
    completition: 'Details about completition',
    profileInformation: 'Profile Information',
    number_of_c_tasks: 'Number of Completed Tasks',
    rating: 'Rating',
    subscribers_p: 'Subscribers',
    rate: 'Rate',
    rateDescribe: 'please leave detail review',
    successAction: 'Success action Thanks!',
    rateAction: 'Please click star to rate!',
    cantRate: 'You already rate..',
    selectRole: 'Select your Role',
    blogger: 'Creator',
    business: 'Business',
    continue: 'Continue?',
    no: 'no',
    yes: 'yes',
    currentStep: 'Post own video and insert link from social networks below: ',
    socialNetwork: ' (social network) ',
    currentStepAfter: " copy link and insert below inside form, then click button 'next': ",
    doneTask: 'Task Submitted',
    waitingApprove: 'Under consideration by business',
    Offers: 'Offers',
    cancel: 'cancel',
    check: 'check',
    dialogCheckMessage: 'You have new suggestion from business please go to Contacts to discuss',
    startTask: 'Start task',
    CopyUrl: 'Copy url',
    next_x: 'Next',
    chat_with_business: 'Chat with Business',
    select_social: 'Select Social Network',
    edit_social: 'Edit Box',
    confirm_edit: 'Confirm',
    edit_confirmation:
      'This field only for update purpose, try field above to add new link if previous video was banned',
    replace_confirmation: 'This video already exixt please add try another to remove banned video',
    edit_button_link: 'Edit Video Link',
    banBoxTitle:
      'This videos were banned please make new videos and upload for this social networks and insert link above to form',
    onlyLocal: 'Involve only local creators',
    highRank: 'Work only with famous influencers',
    searchLocal: 'Search only local business',
    newCompletedUser: '1 user completed task',
    PleaseCheckThisTask: 'Please check this task ',
    PleaseImprove: 'Please improve video ',
    Confirm_task: 'Confirm task',
    UncorfimedTask: 'You Have new unconfirmed task',
    Confirmed_task: 'Task Confirmed',
    Go_offline: 'Go offline',
    who_is: 'please type',
    change_video_text: 'Change video',
    Decline: 'Decline task',
    Sign: 'Sign in',
    Sign_up: 'Sign up',
    Creator: 'Creator',
    Business: 'Business',
    Blogger: 'now in system blogers..',
    echohub_title:
      'it is a software tool for spreading video information through a unlimited network of bloggers',
    All_rights_reserved: 'All rights reserved',
    Follow_Twitter: 'Follow us on Twitter',
    Follow_Youtube: 'Follow us on Youtube',
    Follow_TikTok: 'Follow us on TikTok',
    Follow_Facebook: 'Follow us on Facebook',
    Follow_Instagram: 'Follow us on Instagram',
    Privacy_policy: 'Privacy policy',
    Denial_responsibility: 'Denial of responsibility',
    Subscribe_to_news: 'Subscribe to news',
    Reviews: 'Reviews',
    About_Connect: 'About Us, Connect',
    Patent_USA: 'Patent - 1141, USA',
    Six: '6',
    number_of_subscribersM: 'Numbers of subscribers,',
    locationM: 'location',
    locationMN: 'location h',
    locationMNN: 'location G',
    interestM: 'interest',
    targetA: 'The target audience',
    Large: 'Large reach through a pool of bloggers helps',
    Make: 'make your product or service recognizable!',
    Various: 'Various types of influencers drive more intent to',
    VariousTwo: 'purchase down the funnel and bring a strong ROI',
    Around: 'Around 40% of people',
    reported: 'reported that they',
    purchased: 'purchased a product',
    online_M: 'online after seeing | used',
    online_B: 'by an influencer on',
    online_youtube: 'Youtube, Instagram or',
    online_twitter: 'twitter',
    our_main: 'Our main focus is on nano-influencer',
    our_mainTwo: 'and Micro Influencers.',
    marketers: '89% of marketers say',
    marketers2: 'that ROI from influencer',
    marketers3: 'marketing is',
    marketers4: 'comparable to or better',
    marketers5: 'than other marketing',
    marketers6: 'channels',
    according: 'According to a study by',
    according2: 'Tomson, influencer',
    according3: 'marketing yields a $ 6.50',
    according4: 'return on investment for',
    according5: 'every dollar spent',
    followers: 'FOLLOWERS',
    they_would: 'They would also be crusial to a local business',
    in_a: 'in a particular area.',
    you_r: 'You receive',
    content: 'Content Quality',
    f_reach: 'Follower Reach',
    eng: 'Engagement',
    video_g: 'Video generates 80% more conversion',
    cross_pr: 'Cross promote with us',
    cross_two: 'More engagement likes, and shares for your content',
    cross_three: 'the process is more time-consuming but the ROI is worth it.',
    define: 'Define core',
    defineTwo: 'messages',
    send: 'Send the compaign',
    sendTwo: 'request in Echohub.io',
    cross: 'Cross promoting',
    crossTwo: 'can be pretty easy',
    views: 'Views',
    viewsTwo: 'Social impressions',
    viewsThree: 'Clicks',
    millionN: 'million',
    gpsIn: 'Gps in real time',
    gpsInTwo: 'Our real-time GPS lets businesses request tasks in real-time.',
    gpsInThree: 'and connect to the closest influencer in that area. Built-in',
    gpsInFour: 'algorithm increments the radios by 1 mile',
    analyzeOne: 'Analyze the performance',
    analyzeTwo: '(each creator will be assigned',
    analyzeThree: 'to Unique URL. You will see the',
    analyzeFour: 'amount of people clicked to',
    analyzeFive: 'that url, and see the summary',
    analyzeSix: 'report by social platforms)',
    manageAd: 'Manage ad compaign',
    control: 'Control of task progression',
    acceptT: 'Accept the visual content',
    acceptTwo: 'proposal or recommend a',
    acceptThree: 'change. Chat with influencer',
    SubscP: 'Subscribtion plans',
    p1: 'Quick start',
    p2: '6 compaign',
    p3: '6 influencers',
    p4: '$300',
    p5: 'Massive spreading',
    p6: '12 compaigns',
    p7: '$3000',
    p8: 'Popular',
    p9: '$350',
    p10: 'National',
    p11: '$2700',
    q1: 'What is the name of your business?',
    q2: 'What is your website link to your business?',
    q3: 'Where is your business located?',
    q4: 'Would like to work with only local creators',
    q5: 'Would like to work only with famous creators',
    q6: 'When would you like to start the campaign?',
    q7: 'How much money do you want to pay?',
    q8: 'Who is your buyer persona?',
    q9: 'What is your goal for the campaign?',
    q10: 'Next',
    q11: 'Back',
    bl1: 'Where are you located?',
    bl2: 'Please choose your audience interest?',
    bl3: 'How old are you?',
    bl4: 'Please Enter your First Name?',
    bl5: 'Please Enter your Last Name?',
    bl6: 'Please Enter your Username?',
    bl7: 'please enter Social Network Account Link?',
    bl8: 'How many subscribers do you currently have ?',
    bl9: 'Enter your PayPal email for withdrawal ?',
    bl10: 'Enter SSN,ITIN or EIN for tax information ?',
    bl11: 'Upload at least 1 document to proof identity ?',
    Finish1: 'Finish',
    verification:
      'verifying your profile, you will receive an email notification within 2 hours. We will send you email notification.',
    creator_dashboard: 'Creator dashboard',
    contactsName: 'Contacts',
    myTasks: 'MyTasks',
    currentTasks: 'Current Tasks',
    taskProgress: 'Task Progress',
    tips: 'Included Echohub pay and customer tip',
    high: '(Total may be higher)',
    accept: 'Accept',
    go_online: 'Go Online',
    distance: 'Distance',
    do_before: 'Do before',
    to_decline: 'To decline task please explain reason.',
    form_confirm: 'Confirm',
    reason: 'Please write reason?',
    description_n: 'Description',
    Location_n: 'Location',
    open_task: 'open task',
    under_consideration: 'under consideration by business',
    approved_b: 'approved by business',
    waiting_system_appr: 'waiting system approval',
    continue_task: 'Continue Task',
    suggest_one: 'Suggest',
    suggest_two: 'View',
    socialButton: 'Sign in with social networks',
    close: 'close',
    text1: 'Hi new user ',
    text2: 'Please confirm account by clicking this link ',
    text3: 'Confirmation link',
    text4: 'Confirmation link from echohub.io',
    text5:
      "Congratulations! Your account has been approved, and ready for work with, 'echohub.io' Thank you for being a part of our comunity.",
    text6: 'Your account has been rejected',
    text7: 'Approval user notification from echohub.io',
    text8: 'For additional questions please contact ',
    emailstatus: 'Please confirm your email',
    n1: 'Upload existing video',
    n2: 'Submit a campaign request / Creator would create a video content',
    a1: 'All creators in our system will post your video in own social networks',
    a2: 'Your video will be seen by the total number of subscribers (billions of people around the globe)',
    a3: 'Word of mouth effect',
    a4: 'Analyze the progress of posts by map and list',
    a5: 'Tracking through the dashboard of ongoing and completed jobs.',
    a6: 'Task execution control',
    a7: 'Automated creators search process for your tasks',
    a8: 'Easy interaction with all creators through. (chat, suggest improvement, etc.).',
    a9: 'Advertising campaign management',
    a10: 'AI Echohub.io - helps you to keep tracking all the creators',
    a11: 'Instant video spreading to all social networks',
    b_1: 'Live creator post about your business',
    b_2: '1 business -> many creators',
    b_3: 'More videos -> more opportunity to get high quality and responsive video',
    b_4: 'The ability to work with the nearest creators via GPS or make an application available to all creators.',
    b_5: 'Automated creator search process for your tasks.',
    b_6: 'All creators in our system will publish your video',
    b_7: 'A creator can come and collaborate with you to create a great video..',
    b_8: 'Easy interaction with all creators. (chat, suggest improvement, etc.)',
    b_9: 'You are seeing current progress.',
    b_10: 'Simultaneous posting to (Youtube, Instagram, Tiktok, Twitter, Facebook, Twitter) will create a better spreading effect.',
    b_11: 'Ability to work with an unlimited number of creators.',
    b_12: 'AI Echohub.io - helps you to keep tracking all the creators',
    b_13: 'After creating a video, creators publish your video on all social networks.',
    b_14: 'Our system with creators helps to increase and expand sales',
    b_15: 'In addition, the company can provide clear instructions on what the creator needs to work on and what to focus on..',
    b_16: 'The ability to work simultaneously with a huge number of creators via chat',
    b_17: 'Request to improve the video',
    b_18: 'Your video will be seen by the total number of subscribers (billions of people around the world)',
    b_19: 'Word of mouth effect',
    b_20: 'Analyze the progress of the task',
    b_21: 'Advertising campaign management',
    b_22: 'Task execution control',
    b_23: 'Instant video spreading to all social networks',
    b_24: 'Continue with this plan',
    b_25: 'Continue with plan ',
    b_26: 'Please briefly describe your business',
    b_27: 'Please upload 1 min video about your business',
    b_28: 'go to upload',
    b_29: 'Upload video should be less then 40mb and less than 2 min (standart 1 min)',
    b_30: 'Video uploaded',
    insruction_step1: ' Read description',
    insruction_step2: ' Record video about this project',
    insruction_step3: ' Click upload button and upload video',
    copied: ' Copied to clipboard',
    currentStatus: 'status: ',
    current1: 'please wait, business should approve ',
    step3: 'Download own video and post to own social network ',
    step3_1: 'Click copy button and paste url hash under your video post ',
    step3_2: 'Insert video url from posted video ',
    step3_4: 'Keep posted video in your social network during 7 day',
    step3_5: 'Wait system approval for ',
    step3_5_2: ' day',
    step3_6: 'Every day in different time system checking posted video in your social network',
    step3_7: 'Download own video',
    step3_8: 'Finish the task',
    step3_9: 'Social network video url (link)',
    step3_10: 'Social network new video url (link)',
    editMode: 'Edit task already posted Edit mode not avalable',
    readyToWithdrawal: 'Task ready for withdrawal',
    statuswithdrawal: 'Task ready for withdrawal',
    m1_insruction_step0: 'Read description',
    m1_insruction_step1: 'Download video',
    m1_insruction_step2: 'Copy hash url and put under posted video',
    m1_insruction_step3: 'Make a post',
    m1_insruction_step4: "(Note) If platform instagram post to 'Reels'",
    m1_insruction_step5: 'Copy back link from posted video',
    m1_insruction_step6: 'Keep video during 7 days for system approve',
    m1_insruction_step7: 'Download video to post',
    m1_insruction_step8: 'Submit back link from posted video with form below',
    m1_insruction_step9:
      'Note (Know that castomer may pay Tips for additional posts to other social networks)',
    type_of_post: 'Type',
    type1: 'post video',
    type2: 'record video',
    home: 'Home',
    pricing: 'Pricing',
    Why: 'Why Echohub.io',
    contactUs: 'Contact Us',
    log_in: 'Log in',
    a_1: 'Be famous all over the world',
    a_2: 'with Echohub.io',
    a_3: 'One CodeBase, Drone company',
    a_4: 'Get Started',
    c_1: 'Do you have an active audience?',
    c_2: 'Then Echohub.io is for you!',
    c_3: 'If you have 500 or more subscribers, you have a great opportunity to join us and start making money right now. With model M1 you can just earn money by posting ready video (Passive income) or with model M2 you can record individual video about any business which fit for you. Be part of a powerful community. Because, with us, ou will never be left without work!',
    c_4: 'Create your free account',
    c_5: 'Choose a password',
    c_6: 'Your email',
    c_7: 'Goal',
    c_8: 'Let people with a large number of subscribers monetize their accounts and make passive income.',
    c_9: 'Work process',
    c_10: 'Download video from business',
    c_11: 'Make a post on your social network',
    c_12: 'Get paid',
    c_13: 'Requirents',
    c_14: 'The presence of subscribers at least 500',
    c_15: 'Verify your social network account',
    c_16: 'M1',
    c_17: 'M2',
    c_18: 'Choose the Right Model for You',
    c_19: 'Creator',
    c_20: 'Download video from business',
    c_21: 'Make a post on your social network',
    c_22: 'Get paid',
    bb_13: 'Provide business with a new tool for mass dissemination of information',
    bb_14:
      'It is an innovative platform that connects all people with a large number of subscribers around the world. We allowed people with a large number of followers to monetize their social networks, and companies to massively distribute videos about their business. All you need is to upload your video about your business to echohub.io, and all the creators of our system will publish your video on their social networks. On our platform, we have united all subscribers around the world, which allows your business to become instantly recognizable.',
    bb_15: 'Provide business with a new innovative tool for the mass dissemination of information',
    bb_16: 'Business',
    user_1: 'Post Points',
    user_2: 'Points',
    user_3: 'Bio',
    user_4: 'first name',
    user_5: 'last name',
    user_6: 'First Name',
    user_7: 'Last Name',
    user_8: 'Bio',
    user_9: 'Price',
    user_10: 'Looking for tasks..',
    user_11: 'Profile',
    user_12: 'Logout',
    user_13: 'Dashboard',
    user_14: 'Go to Dashboard',
    user_15:
      "This program does the same as Elon Musk's tweet, and the whole world will know about it. Why, like Elon Musk, because we collect all people with their subscribers around the world, this allowed us to collect a large number of subscribers, and when a business sends videos to our program, all the people who are on our platform just publish your videos in their own social networks.",
    about_1:
      'Yquantum - Drone company. Contact us:info@yquantum.org',
    no_messagesYet: 'no messages yet..',
    download_app: 'Download mobile app',
    download_app_2: 'Download our mobile apps',
    select_address: 'Please select your address',
    select_age: 'Please write your age',
    socialNetworkAge: 'how old is your social network account',
    newReq: 'New Request',
    videoDontexist: 'video error?',
    videoDontexistCreatorSide:
      'Your video post was not found Please insert a working link from network below',
    editMode7Days:
      'Editing mode is not available because the system has already counted 7 days since the user posted video.',
    chooseBlogger: 'Choose creators',
    startCompaign: 'Start Compaign',
    budget: 'your budget (usd)',
    minSum: 'minimum sum should be at least 1$',
    dialogDefaultText:
      'AI Echohub.io will choose best bloggers for you and will distribute your budget. Do you want to do it?',
    dialogDefaultTextCheckbox:
      'AI Echohub.io will choose best bloggers for you and will distribute your budget',
    confirm_small: 'confirm',
    cancel_small: 'cancel',
    creators_business: 'Building future together!',
    business_continue: 'business',
    bb_11: 'Drone technology',
    know: 'company',
    bb_12: 'about your',
    inSystem: 'Bloggers in the system',
    total_N: 'Total subscribers',
    who_we_are: 'Who we are and what we offer to',
    who_we_areN: 'solve',
    who_we_areN2: 'your problems',
    who_we_areN3:
      'We are a young startup development team from Silicon Valley, California, with an enormous love for what we do.',
    who_we_areN4:
      "Our story began when David, the company's founder, had a spark and then a dream to become a game-changer and create a new approach to the interaction of businesses with Influencers and content creators.",
    who_we_areN5:
      'We want to change the current inefficient system (as Uber - taxi service did) and introduce a new one to the world - by replacing the model of business interaction with one content creator with simultaneous cooperation with an unlimited number.',
    who_we_areN6:
      'We created Echohub.io - the innovative platform,  the new tool for a massive and instant video distribution about your brand between all active users of social networks in our system.',
    increase: 'Increase',
    increase2: 'Brand',
    increase3: 'Awareness and Sales',
    increase4:
      'We make videos about your business to attract the attention of people all over the world',
    giveContent: 'Give content creators and',
    giveContent1: 'influencers',
    giveContent2: 'opportunities',
    giveContent3: 'to post and create videos with brands and get paid',
    giveContent4:
      'We help social media users monetize their creative ideas and generate passive income',
    simplify: 'Simplify and',
    simplify1: ' automate',
    simplify2: ' the distribution process of your videos to influencers and content creators',
    simplify3:
      'No more searching and costly actions to contact and interact with active social media users',
    make0: 'Make it',
    make1: 'simple ',
    make2: 'and',
    make3: 'easy',
    make4: 'for you to manage and track the advertising effectiveness',
    make5:
      'You can monitor the progress and analyze the results of the advertising campaign by yourself',
    improve0: 'Improve',
    improve1: 'the entire process of interaction between businesses and social media users',
    improve2:
      'Thanks to our professionalism, constant support, an inherent desire to make your business recognizable and allow Influencers and content creators to collaborate effectively and turn their passion for video creation into a business',
    reports: 'Get daily, weekly ',
    reports0: 'reports',
    reports1:
      'You have a dashboard with all information what you need, analysis the progress of implemented posts and their success, track progress on map, how many users came to your business from particular influencer and from which country and be connected with them',
    dontWait: "Don't wait for your",
    dontWait1: 'competitors ',
    dontWait2: 'to beat you. Instead,',
    dontWait2_1: 'innovate',
    dontWait2_2: 'and',
    dontWait2_3: 'stay',
    dontWait3: 'ahead of the curve, partner with us, and reach a whole new level of development!',
    order: 'Order ',
    order0: 'lightning-fast',
    order0_1: 'and',
    order0_2: 'engaging ',
    order1_1: "(just like Elon Musk's tweet!) ",
    order1_2: 'post or video content ',
    order1_3: 'for your business right now.',
    profile_link: 'Social network url',
    profile_link2: 'Click to see ',
    profile_link3: 'Social network profile ',
    membershipDialogText1: 'Try 1 month free membership',
    membershipDialogText2: 'See detail information',
    membershipDialogText3: 'Get any information faster',
    membershipDialogText4: 'Stay connected',
    membershipDialogText5: 'See prices and reviews',
    membershipDialogText6: 'Connect with our advisor',
    membershipDialogConfirm: 'continue ',
    membershipDialogCancel: 'cancel ',
    lastBusinessPost: 'Last Business Video Orders',
    postearnMoney: '*Post this task and earn money right now',
    fullView: 'Go to full view',
    lastCreatorPosts: 'Last Creators Posts',
    trial_payment: 'Subscribe with free month trial period',
    email_Translate: 'Email',
    email_Milestone: 'Milestone',
    email_Actions: 'Actions',
    action_required: 'This blogger changing video',
    TotalPosts: 'Total Posts',
    posts_efficiency: 'efficiency of posts',
    performance: 'Performance',
    Accounts: 'Accounts',
    conversion: 'Conversion',
    efficiency_views: 'Views',
    incomingConversion: 'Conversion in GB',
    newFollowers: 'Total New Followers',
    visited_users: 'Visited Users',
    Customers_feedback: 'Customers feedback',
    errors: 'Not followed',
    last_hour: 'In the last hours',
    projected_income: 'Projected company income',
    completedTasks: 'Completed Tasks',
    global_posts_by_locations: 'Global Sales by Locations',
    all_posts_where_posted: 'All Posts where posted',
    b_posted: 'Posted',
    b_hours: 'hours ago',
    latestNews: 'Latest Company News'
  },

  ru: {
    login: 'Регистрация/Вход',
    login_button: 'Войти',
    password_length: 'Пароль слишком короткий - должен быть не менее 6 символов.',
    restore_password: 'Забыли пароль',
    restore_button: 'Восстановить',
    user_not_found:
      'Пользователь с данным email не найден, попробуйте другой email или возможно вы не зарегистрированы',
    system_notification: 'Уведомление',
    password_mail_notification: 'Пароль был отправлен на ваш email',
    company_name: 'Введите название компании',
    title_name: 'Введите URL вебсайта',
    description_name: 'Описание',
    description_placeholder:
      'Опишите текущую задачу для бизнеса и что вы хотите услышать от создателей,создателей видео',
    location_name: 'введите адрес',
    location_error: 'Выберите локацию',
    time_name: 'Выберите время',
    amount_name: 'Сумма ',
    amount_placeholder: 'давайте подсчитаем сколько создателей будет вовлечено',
    date_name: 'Дата начала',
    continue_button: 'Продолжить',
    count_of_bloggers: 'Количество вовлеченных блогеров',
    count_of_subscribers: 'Примерный охват',
    choose_payment: 'Пожалуйста сделайте платеж',
    request: 'Запрос ',
    paid: 'Оплачено ',
    approximate_reach: 'Приблизительный охват ',
    involved_bloggers: 'Вовлеченных блогеров ',
    platforms_in_use: 'Использующие платформы ',
    tiktok: 'Tiktok ',
    instagramm: 'Instagram ',
    facebook: 'Facebook ',
    twitter: 'Twitter ',
    list_of_bloggers: 'Список создателей',
    view: 'Посмотреть',
    profile: 'Профиль',
    detail: 'Подробнее',
    completition: 'Подробности о завершении',
    profileInformation: 'Информация о профиле',
    number_of_c_tasks: 'Количество завершенных задач',
    rating: 'Рейтинг',
    subscribers_p: 'Подписчиков',
    rate: 'Оценить',
    rateDescribe: 'пожалуйста оставьте подробный отзыв',
    successAction: 'Успешное действие, Спасибо!',
    rateAction: 'Пожалуйста нажмите на звезду,чтобы оценить!',
    cantRate: 'Уже оценено..',
    selectRole: 'Выберите вашу роль',
    blogger: 'Создатель',
    business: 'Бизнес',
    continue: 'Продолжить?',
    no: 'нет',
    yes: 'да',
    currentStep:
      'Опубликуйте свое видео с социальной сети и вставьте обратную ссылку ниже в поле: ',
    socialNetwork: ' (социальные сети) ',
    currentStepAfter: " скопируйте ссылку и вставьте ниже в форму, далее нажмите кнопку 'далее': ",
    doneTask: 'Задание отправлено',
    waitingApprove: 'на рассмотрений у бизнеса',
    Offers: 'Предложения',
    cancel: 'отменить',
    check: 'проверить',
    dialogCheckMessage:
      'У вас есть новое предложение от бизнеса пожалуйста перейдите в контакты чтобы обсудить',
    startTask: 'Начать задание',
    CopyUrl: 'Скопировать ссылку',
    next_x: 'Дальше',
    chat_with_business: 'Написать бизнесу',
    select_social: 'Выбрать социальную сеть',
    edit_social: 'исправление',
    confirm_edit: 'Подтвердить',
    edit_confirmation:
      'это поле только для целей обновлений, попробуйте использовать поле выше, чтобы добавить новую ссылку если предыдущее видео было заблокировано',
    replace_confirmation: 'это видео уже существует, пожалуйста попробуйте другую видео ссылку',
    edit_button_link: 'Исправить видео ссылку',
    banBoxTitle:
      'Это видео было заблокировано пожалуйста сделаете новое видео и загрузите для этих социальных сетей и вставьте ссылку выше',
    onlyLocal: 'Привлечь только ближайших создателей (GPS)',
    highRank: 'Работать только с известными создателями',
    searchLocal: 'Поиск ближайшего бизнеса(GPS)',
    newCompletedUser: '1 пользователь закончил задание',
    PleaseCheckThisTask: 'Пожалуйста проверьте это задание ',
    PleaseImprove: 'Пожалуйста улучшите видео  ',
    Confirm_task: 'Подтвердить задание',
    UncorfimedTask: 'У вас есть неподтвержденное задание',
    Confirmed_task: 'Задание подтверждено',
    Go_offline: 'Стать offline',
    who_is: 'пожалуйста заполните',
    change_video_text: 'Заменить видео',
    Decline: 'Отклонить задание',
    Sign: 'Войти',
    Sign_up: 'Войти',
    Creator: 'Создатель',
    Business: 'Бизнес',
    Blogger: 'сейчас в системе создателей..',
    echohub_title:
      'это программный инструмент для распространения видеоинформации через неограниченную сеть блогеров',
    All_rights_reserved: 'Все права защищены',
    Follow_Twitter: 'Мы в Twitter',
    Follow_Youtube: 'Мы в Youtube',
    Follow_TikTok: 'Мы в TikTok',
    Follow_Facebook: 'Мы в Facebook',
    Follow_Instagram: 'Мы в Instagram',
    Privacy_policy: 'Политика конфиденциальности',
    Denial_responsibility: 'Отказ от ответственности',
    Subscribe_to_news: 'Подпишитесь на новости',
    Reviews: 'Оценки',
    About_Connect: 'О нас',
    Patent_USA: 'Патент - 1141, USA(США)',
    Six: '6',
    number_of_subscribersM: 'суммарное количество',
    locationM: 'подписчиков',
    interestM: 'вы',
    locationMN: 'получите',
    targetA: 'массовую публикацию',
    locationMNN: 'каждого создательа',
    Large: 'Мгновенный эффект распространения видео от',
    Make: 'разных блогеров за короткий промежуток времени',
    Various: '1 Бизнес -> много создателей',
    VariousTwo: '1 интерфэйс и легкое взаимодействие со всеми',
    Around: 'Потрать те же деньги ',
    reported: 'найми больше малых',
    purchased: 'блогеров для большего',
    online_M: 'эффекта,чем потратить',
    online_B: 'большую сумму на',
    online_youtube: 'неизвестный результат',
    online_twitter: 'Доказано в работе.',
    our_main: 'Наша цель решить вашу задачу и дать',
    our_mainTwo: 'лучший видео контент и опыт работы',
    marketers: '89% маркетологов говорят',
    marketers2: 'работа блогеров',
    marketers3: 'отличные идеи',
    marketers4: 'видео контент',
    marketers5: 'приносит больше',
    marketers6: 'откликов и результатов',
    according: 'Согласно исследованию',
    according2: 'Живое видео',
    according3: 'приносит большую',
    according4: 'окупаемость за каждый',
    according5: 'потраченный доллар',
    followers: 'ПОДПИСЧИКИ',
    they_would: 'После создания блогеры публикуют ваше видео во',
    in_a: 'все социальные сети.',
    you_r: 'Вы получаете',
    content: '5+ видео',
    f_reach: 'Большой охват',
    eng: 'Результат',
    video_g: 'Эффект сарафанного радио',
    cross_pr: 'Продвигайся с нами',
    cross_two: 'Возможность работы с неограниченным количеством создателей',
    cross_three: 'полность автоматизированный процесс занимает меньше времени',
    define: 'Создай',
    defineTwo: 'заявку',
    send: 'Опиши',
    sendTwo: 'свою задачу',
    cross: 'Получи',
    crossTwo: 'результат',
    views: 'Просмотры',
    viewsTwo: 'Переходы',
    viewsThree: 'Клики',
    millionN: 'миллион',
    gpsIn: 'Gps в реальном времени',
    gpsInTwo: 'Возможность работы с ближайшими создателями по GPS',
    gpsInThree: 'или сделать заявку доступной для',
    gpsInFour: 'всех создателей',
    analyzeOne: 'Анализируй прогресс выполнения',
    analyzeTwo: '(каждому создательу будет назначен',
    analyzeThree: 'уникальный URL. Вы увидите',
    analyzeFour: 'количество кликов и переходов',
    analyzeFive: 'через вашу ссылку, и',
    analyzeSix: 'отчет по графикам)',
    manageAd: 'Управляй рекламной кампанией',
    control: 'Контроль выполнения задач',
    acceptT: 'Одобрение видео контента',
    acceptTwo: 'предлагай или рекомендуй',
    acceptThree: 'улучшение видео. Чат с блогером',
    SubscP: 'Планы подписки',
    p1: 'Быстрый старт',
    p2: '6 запросов',
    p3: '6 создателей',
    p4: '$300',
    p5: 'Массивное',
    p6: '12 запросов',
    p7: '$3000',
    p8: 'Популярный',
    p9: '$350',
    p10: 'Национальный',
    p11: '$2700',
    q1: 'Укажите название вашего бизнеса?',
    q2: 'Укажите website вашего сайта?',
    q3: 'Укажите локацию вашего бизнеса?',
    q4: 'Работать только с местными создателями по GPS?',
    q5: 'Работать только с известными создателями?(Дороже)',
    q6: 'Выберите дату начала?',
    q7: 'Выберите свой депозит?',
    q8: 'Кто ваш покупатель?',
    q9: 'Какова цель компании?',
    q10: 'Дальше',
    q11: 'Назад',
    bl1: 'Где вы находитесь?',
    bl2: 'Выберите тип вашей аудитории?',
    bl3: 'Сколько вам лет?',
    bl4: 'Введите ваше имя?',
    bl5: 'Введите вашу фамилию?',
    bl6: 'Придумайте имя пользователя?',
    bl7: 'Вставьте ссылку вашей страницы с социальной сети?',
    bl8: 'Сколько подписчиков у вас есть?',
    bl9: 'Введите аккаунт PayPal email для вывода заработанных средств?',
    bl10: 'Введите SSN,ITIN или EIN для налоговой отчетности?',
    bl11: 'Загрузите хотя бы 1 документ чтоб подтвердить свою личность?',
    Finish1: 'Закочить',
    verification:
      'подтвердив свой профиль, вы получите уведомление по электронной почте в течение 2 часов. Мы отправим вам уведомление по электронной почте.',
    creator_dashboard: 'Создатель',
    contactsName: 'Контакты',
    myTasks: 'Мои задания',
    currentTasks: 'текущие задания',
    taskProgress: 'Прогресс заданий',
    tips: 'Сумма echohub.io и вознаграждения клиента',
    high: '(Сумма может быть выше)',
    accept: 'Принять',
    go_online: 'Идти Online',
    distance: 'Расстояние',
    do_before: 'Выполнить до',
    to_decline: 'Обясните причину отклонения.',
    form_confirm: 'Подтвердить',
    reason: 'текст',
    description_n: 'Описание',
    Location_n: 'Локация',
    open_task: 'выполнение',
    under_consideration: 'на рассмотрении бизнеса',
    approved_b: 'одобрено бизнесом',
    waiting_system_appr: 'ожидание одобрения системы',
    continue_task: 'Перейти к заданию',
    suggest_one: 'предложить улучшение',
    suggest_two: 'посмотреть',
    socialButton: 'Вход через социальные сети',
    close: 'закрыть',
    text1: 'Привет пользователь ',
    text2: 'Пожалуйста подтвердите свой аккаунт, перейдя по этой ссылке ',
    text3: 'Ссылка подтверждения',
    text4: 'Ссылка подтверждения от echohub.io',
    text5: 'Поздравляю! Ваш аккаунт одобрен и готов к работе с echohub.io',
    text6: 'Ваш аккаунт был отклонен!',
    text7: 'Уведомление об одобрении аккаунта echohub.io',
    text8: 'Для дополнительных вопросов обращаться к ',
    emailstatus: 'Email не подтвержден',
    n1: 'Загрузить существующее видео',
    n2: 'Создай заявку / создатели будут создавать видео контент',
    a1: 'Все «Создатели» в нашей системе опубликуют Ваше видео',
    a2: 'Ваше видео будет анонсироваться для миллиардов людей по всему Земному шару',
    a3: 'Эффект сарафанного радио',
    a4: 'Анализируй прогресс постов по карте и списком',
    a5: 'Отслеживание через dashboard текущих и завершенных работ.',
    a6: 'Контроль выполнения задач',
    a7: 'Автоматизированный процесс поиска создателя для ваших задач.',
    a8: 'Легкое взаимодействие со всеми создателями через систему. (Сообщения, сообщить об улучшении и т.д).',
    a9: 'Управление рекламной компанией',
    a10: 'AI Echohub.io - помогает вам контролировать работу создателей.',
    a11: 'Моментальное распространение во все социальные сети',
    b_1: 'Живая запись создателей о вашем бизнесе',
    b_2: '1 бизнес -> много создателей',
    b_3: 'Большее количество видео -> больше возможности в получении качественного и отзывчивого видео',
    b_4: 'Возможность работы с ближайшими создателями по GPS или сделать заявку доступной для всех создателей «Возможность выбора работы»',
    b_5: 'Автоматизированный процесс поиска создателя для ваших задач.',
    b_6: 'Все «Создатели» в нашей системе опубликуют Ваше видео',
    b_7: 'Создатель может приехать и сотрудничая с вами создать отличное видео.',
    b_8: 'Легкое взаимодействие со всеми создателями через систему. (Сообщения, сообщить об улучшении и т.д)',
    b_9: 'Вы видите текущий прогресс',
    b_10: 'Одновременные посты в (Youtube, Instagram,Tiktok,Twitter,Facebook,Twitter) создадут лучший эффект распространения.',
    b_11: 'Возможность работы с неограниченным количеством создателей.',
    b_12: 'AI Echohub.io - помогает вам контролировать работу создателей.',
    b_13: 'Публикация уникального видео от каждого создателя во всех социальных сетях',
    b_14: 'Увеличение и распространение ваших продаж',
    b_15: 'Также бизнес может описать четкие указания того, над чем создателю нужно работать и сосредоточится.',
    b_16: 'Одновременная возможность работы с огромным количеством создателей через чат',
    b_17: 'Запрос на улучшение видео',
    b_18: 'Ваше видео будет анонсироваться для миллиардов людей по всему Земному шару',
    b_19: 'Эффект сарафанного радио',
    b_20: 'Анализируй прогресс выполнения задания',
    b_21: 'Управление рекламной компанией',
    b_22: 'Контроль выполнения задач',
    b_23: 'Моментальное распространение во все социальные сети',
    b_24: 'Продолжить с данным планом',
    b_25: 'Продолжить с планом 2',
    b_26: 'Пожалуйста, кратко опишите свой бизнес',
    b_27: 'Пожалуйста загрузите 1 минутное видео о вашем бизнесе',
    b_28: 'Перейти к загрузке',
    b_29: 'Видео должно быть мньше чем 40mb и не более 2 min (1 минута стандарт)',
    b_30: 'Видео загружено',

    insruction_step1: ' Прочитать описание',
    insruction_step2: ' Записать видео об этом проекте',
    insruction_step3: ' Нажмите кнопку загрузки и загрузите видео',
    copied: ' Скопировано',
    currentStatus: 'статус: ',
    current1: 'Пожалуйста, подождите, бизнес должен одобрить ',
    step3: 'Загрузите собственное видео и разместите в своей социальной сети ',
    step3_1: 'Нажмите кнопку копировать и вставьте хеш URL под своим видео ',
    step3_2: 'Вставить URL видео из опубликованного видео ',
    step3_4: 'Держать публикацию видео в своей социальной сети в течение 7 дней',
    step3_5: 'Подождать одобрения системы в течение ',
    step3_5_2: ' дней',
    step3_6:
      'Каждый день в разное время система проверяет размещенное видео в вашей социальной сети',
    step3_7: 'Загрузить ваше бизнес видео',
    step3_8: 'Завершить задачу',
    step3_9: 'URL видео в социальной сети (ссылка)',
    step3_10: 'Новый URL видео в социальной сети (ссылка)',
    editMode: 'Режим редактирования недоступен',
    readyToWithdrawal: 'Доступен вывод средств',
    statuswithdrawal: 'Доступен вывод средств',
    m1_insruction_step0: 'Прочитать описание',
    m1_insruction_step1: 'Сделайте пост',
    m1_insruction_step2: 'Скопируйте URL-адрес хеша и поместите под опубликованное видео',
    m1_insruction_step3: 'Make a post',
    m1_insruction_step4: "(Примечание) Если платформа Instagram опубликуйте в 'Reels'",
    m1_insruction_step5: 'Скопировать обратную ссылку из опубликованного видео',
    m1_insruction_step6: 'Держите видео в течение 7 дней для утверждения системой',
    m1_insruction_step7: 'Загрузить видео для поста',
    m1_insruction_step8: 'Отправить обратную ссылку из опубликованного видео с помощью формы ниже',
    m1_insruction_step9:
      'Обратите внимание (знайте, что клиент может платить чаевые за дополнительные публикации в других социальных сетях)',
    type_of_post: 'Тип',
    type1: 'видео пост',
    type2: 'записать видео',
    home: 'Главная',
    pricing: 'Цены',
    Why: 'Почему Echohub.io',
    contactUs: 'Свяжитесь с нами',
    log_in: 'Войти',
    a_1: 'Будьте известны во всем мире',
    a_2: 'с Echohub.io',
    a_3: 'Это инновационная платформа, которая объединяет всех людей с большим количеством подписчиков по всему миру. Мы позволили людям с большим количеством подписчиков монетизировать свои социальные сети, а компаниям - массово распространять видео о своем бизнесе.',
    a_4: 'Быстрый старт',
    c_1: 'У вас есть подписчики?',
    c_2: 'Згачит Echohub.io is для тебя!',
    c_3: 'Если у вас 500 или более подписчиков, у вас есть прекрасная возможность присоединиться к нам и начать зарабатывать прямо сейчас. С моделью M1 вы можете просто зарабатывать деньги, размещая готовое видео (пассивный доход), а с моделью M2 вы можете записывать индивидуальное видео о любом бизнесе, который вам подходит. Станьте частью огромного сообщества. Потому что с нами ты никогда не останешься без работы!',
    c_4: 'Создайте свою бесплатную учетную запись',
    c_5: 'Создайте пароль',
    c_6: 'Ваш email',
    c_7: 'Цель',
    c_8: 'Позволить людям с большим количеством подписчиков монетизировать свои аккаунты и получать пассивный доход.',
    c_9: 'Рабочий процесс',
    c_10: 'Скачать бизнес видео',
    c_11: 'Сделайте пост в своей социальной сети',
    c_12: 'Получите деньги',
    c_13: 'Требования',
    c_14: 'Наличие подписчиков не менее 500',
    c_15: 'Подтвердите свою учетную запись в социальной сети',
    c_16: 'M1',
    c_17: 'M2',
    c_18: 'Выберите подходящую модель для вас',
    c_19: 'Создатель',
    c_20: 'Скачать видео с бизнеса',
    c_21: 'Сделайте пост в своей социальной сети',
    c_22: 'Получите деньги',

    bb_13: 'Предоставить бизнесу новый инструмент для массового распространения информации',
    bb_14:
      'Это инновационная платформа, которая объединяет всех людей с большим количеством подписчиков по всему миру. Мы позволили людям с большим количеством подписчиков монетизировать свои социальные сети, а компаниям - массово распространять видео о своем бизнесе. Все, что вам нужно, это загрузить видео о своем бизнесе на echohub.io, и все создатели нашей системы опубликуют ваше видео в своих социальных сетях. На нашей платформе мы объединили всех подписчиков по всему миру, что позволяет мгновенно сделать ваш бизнес узнаваемым.',
    bb_15:
      'Предоставить бизнесу новый инновационный инструмент для массового распространения информации',
    bb_16: 'Бизнес',
    user_1: 'Пост Поинты',
    user_2: 'Поинтов',
    user_3: 'Биография',
    user_4: 'Имя',
    user_5: 'Фамилия',
    user_6: 'Имя',
    user_7: 'Фамилия',
    user_8: 'Биография',
    user_9: 'Цена',
    user_10: 'Ищу задания..',
    user_11: 'Профиль',
    user_12: 'Выход',
    user_13: 'в Систему',
    user_14: 'Перейти в Систему',
    user_15:
      'Эта программа делает то же самое, что и твит Илона Маска, и об этом узнает весь мир. Почему, как Илон Маск, потому что мы собираем всех людей с их подписчиками по всему миру, это позволило нам собрать большое количество подписчиков, а когда бизнес отправляет видео в нашу программу, все люди, которые находятся на нашей платформе, просто публикуют ваши видео в собственных социальных сетях.',
    about_1:
      'Привет, Я Дулат». Живу в Walnut Creek CA. Сейчас я работаю над космическим двигателем и мне нужна команда для исследований и разработки конечного продукта способный путешевствовать со скоростью близкой к магнетизму, вы можете связаться со мной по телефону 6503094570 или email 2clickorg@gmail.com',
    no_messagesYet: 'у вас нет сообщений..',
    download_app: 'Загрузи мобильное приложение',
    download_app_2: 'Загрузи наши мобильные приложения',
    select_address: 'Укажите свой адресс',
    select_age: 'Сколько вам лет',
    socialNetworkAge: 'Cколько лет вашему аккаунту социальной сети',
    newReq: 'Новый запрос',
    videoDontexist: 'заменить пост?',
    videoDontexistCreatorSide:
      'Ваше видео не найдено. Пожалуйста, вставьте рабочую ссылку из социальной сети ниже.',
    editMode7Days:
      'Режим редактирования недоступен, потому что система уже отсчитала 7 дней с момента публикации видео пользователем',

    chooseBlogger: 'Выбор создателей',
    startCompaign: 'Начать компанию',
    budget: 'ваш бюджет (usd)',
    minSum: 'минимум 1$',
    dialogDefaultText:
      'AI Echohub.io выберет для вас лучших создателей и распределит ваш бюджет. Вы согласны?',
    dialogDefaultTextCheckbox:
      'AI Echohub.io выберет для вас лучших создателей и распределит ваш бюджет',
    confirm_small: 'подтвердить',
    cancel_small: 'отменить',
    creators_business: 'Создатели&Бизнесы',
    business_continue: 'бизнесе',
    bb_11: 'Пусть весь мир',
    know: 'узнает',
    bb_12: 'о вашем',
    inSystem: 'Создателей в системе',
    total_N: 'Всего подписчиков',

    who_we_are: 'Кто мы и как наша платформа решит',
    who_we_areN: 'решит',
    who_we_areN2: 'Ваши проблемы',
    who_we_areN3:
      'Мы — молодая и одержимая своим делом команда разработчиков стартапа из Кремниевой долины, Калифорнии.',
    who_we_areN4:
      'Наша история началась с того, что у Дулата , основателя компании, появилась мечта стать гейм-чейнджером, создать новый подход к взаимодействию бизнесов с инфлюенсерами и создателями контента.',
    who_we_areN5:
      'Мы хотим изменить текущую неэффективную систему (как это сделал Uber - сервис такси) и представить миру новую - путем замены модели взаимодействия бизнеса с одним контент-криейтором на одновременное сотрудничество сразу с неограниченным количеством.',
    who_we_areN6:
      'Мы создали Echohub.io - инновационную платформу, инструмент для массового и мгновенного распространения видео о Вашем бренде между всеми активными пользователями социальных сетей в нашей системе.',

    increase: 'Повышаем',
    increase2: 'узнаваемость',
    increase3: 'бренда и увеличиваем продажи',
    increase4:
      'Делаем  так, чтобы видео о Вашем бизнесе привлекало внимание людей по всему земному шару',
    giveContent: 'Даем возможность создателям контента и',
    giveContent1: 'инфлюенсерам',
    giveContent2: 'возможность',
    giveContent3: 'делать пост, создавать видео с брендами и получать за это деньги',
    giveContent4:
      'Помогаем активным пользователям социальных сетей монетизировать свои творческие идеи и получать пассивный доход',
    simplify: 'Автоматизируеи и',
    simplify1: ' упрощаем',
    simplify2: ' процесс распространения Вашего видео среди инфлюенсеров и создателей контента',
    simplify3:
      'Больше никаких поисков и затратных действий для установления контакта и взаимодействия с активными пользователями социальных сетей',
    make0: 'Делаем ',
    make1: 'управление ',
    make2: 'и',
    make3: 'отслеживание',
    make4: 'эффективности рекламы простым и легким для Вас',
    make5:
      'Делаем так, чтобы Вы можете самостоятельно контролировать прогресс и анализировать результаты рекламной кампании',
    improve0: 'Улучшаем',
    improve1: 'весь процесс взаимодействия бизнесов с активными пользователями социальных сетей',
    improve2:
      'Благодаря нашему профессионализму, постоянной поддержке и безудержным стремлением сделать Ваш бизнес узнаваемым, а инфлюенсерам и создателям контента предоставить возможность эффективно сотрудничать и превратите свою страсть к созданию видео в бизнес',
    reports: 'Получайте ежедневные, еженедельные ',
    reports0: 'отчеты',
    reports1:
      'У вас есть панель отслеживания и контроля со всей информацией о состоянии ваших дел, анализируй прогресс постов и их успех, отслеживай прогресс на карте,сколько пользователей пришли к вам от определенных создателей контента и с какой страны , будь соединен с ними',
    dontWait: 'Не ждите, когда',
    dontWait1: 'конкуренты ',
    dontWait2: ' обойдут Вас на повороте! ',
    dontWait2_1: 'Сотрудничайте ',
    dontWait2_2: 'с',
    dontWait2_3: 'нами',
    dontWait3: 'и выходите на принципиально новый уровень развития!',
    order: 'Закажите ',
    order0: 'молниеносное',
    order0_1: 'и',
    order0_2: 'эффективное ',
    order1_1: '(как и твит Илона Маска!) ',
    order1_2: 'видео-пост или создание видео ',
    order1_3: 'для Вашего бизнеса прямо сейчас.',
    profile_link: 'url Социальной сети',
    profile_link2: 'Перейти ',
    profile_link3: 'url Социальной сети ',
    membershipDialogText1: 'Попробуй 1 месяц пробного периуда',
    membershipDialogText2: 'Просматривай подробную информацию',
    membershipDialogText3: 'Получай информацию мгновенно',
    membershipDialogText4: 'Будь соединен',
    membershipDialogText5: 'Смотри отзывы и ревью',
    membershipDialogText6: 'Получай советы от поддержки',
    membershipDialogConfirm: 'продалжить ',
    membershipDialogCancel: 'отменить ',
    lastBusinessPost: 'Последние бизнес заявки',
    postearnMoney: '*Опубликуй это задание и зарабатывай прямо сейчас',
    fullView: 'полная версия',
    lastCreatorPosts: 'Последние посты создателей',
    trial_payment: 'Подпишись с бесплатным месяцем пробного периуда',
    email_Translate: 'Email',
    email_Milestone: 'Этап',
    email_Actions: 'Действия',
    action_required: 'Этот блогер меняет видео',
    TotalPosts: 'Общее количество постов',
    posts_efficiency: 'Эффективность постов',
    performance: 'Эффективность',
    Accounts: 'Аккаунты',
    conversion: 'Конверсия',
    efficiency_views: 'Просмотры',
    incomingConversion: 'Конверсия в GB',
    newFollowers: 'Новых подписчиков',
    visited_users: 'Визиты пользователей',
    Customers_feedback: 'Отзыв клиентов',
    errors: 'Не подписались',
    last_hour: 'за последний час',
    projected_income: 'Прогнозируемый доход компании',
    completedTasks: 'Завершено заданий',
    global_posts_by_locations: 'Выполнение заданий по регионам',
    all_posts_where_posted: 'Все опубликованные посты',
    b_posted: 'Опубликовано',
    b_hours: 'часов назад'
  },

  it: {
    login: 'Login/Registration',
    login_button: 'Join',
    password_length: 'Password is too short - should be at least 6 chars minimum.',
    restore_password: 'Forgot password',
    restore_button: 'Restore',
    user_not_found:
      'User with this email not found please try another email or may be you are not registered',
    system_notification: 'Notification',
    password_mail_notification: 'The password has been sent to your email',
    title_name: 'Name of business or url',
    description_name: 'Description',
    description_placeholder:
      'Please write description about your business and what you want to hear from creators',
    location_name: 'Chose location',
    time_name: 'Choose time',
    amount_name: 'Amount',
    amount_placeholder: 'lets count how many creators will involved',
    date_name: 'Start date',
    continue_button: 'Continue'
  }
});

export default strings;
